import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import LandingSection from '../components/landingSection/landingSection';
import { useTheme } from '@mui/material/styles'

const NotFoundPage = ({ data }) => {
  const theme = useTheme()
  const videos = data?.allYoutubeVideo.edges?.map(edge=>edge.node)
  let videoThumbnails = {}
  data?.allFile?.edges?.forEach(edge=>{
    videoThumbnails[edge.node.base.replace('.webp','')] = {...edge.node}
})
  return (
     typeof window !== undefined
      &&
      <Layout page="home" videos={videos}>
         <main style={{ overflowX: "hidden", overflowY:"hidden", height:'100%', backgroundColor:theme.palette.gradients.richBlack }}>
           <LandingSection videos={videos} videoThumbnails={videoThumbnails} images={{cinema:data.file}}/>
         </main>
      </Layout>
  );
};
export default NotFoundPage

export const query = graphql`
  query {
    allYoutubeVideo {
      edges {
        node {
          videoId
          channelId
          title
          description
          thumbnail {
            url
            width
            height
          }
        }
      }
    }
    datoCmsTitlePage {
      backgroundImage{
        gatsbyImageData(
          width: 1500,
          placeholder: BLURRED,
          forceBlurhash: false,
        )
      }
      backgroundImageAlt{
        gatsbyImageData(
          width: 1500,
          placeholder: BLURRED,
          forceBlurhash: false,
        )
      }
    }
    allDatoCmsPortfolio {
      edges {
        node {
          businessName
          description
          id
          url
          logo {
            gatsbyImageData(
              width: 300,
              placeholder: BLURRED,
              forceBlurhash: false,
            )
          }
        }
      }
    }
    datoCmsLayout{
      backgroundImage{
        gatsbyImageData(
          width: 1920,
          placeholder: BLURRED,
          forceBlurhash: false,
        )
      }
    }
    file(base: {eq: "cinema.webp"}) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          width: 1920,
          height:1080
        )
      }
    }
    allFile(
      filter: {extension: {eq: "webp"}, relativeDirectory: {eq: "videosSection"}}
      )
    {
      edges{
        node{
          id
          base
          childImageSharp{
            gatsbyImageData(
              width:370
            )
          }
        }
      }
    }
  }
`;
